import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-panel-fancy-forms',
  templateUrl: './panel-fancy-forms.component.html',
  styleUrls: ['./panel-fancy-forms.component.scss']
})
export class PanelFancyFormsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
