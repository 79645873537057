import { Component, OnInit } from '@angular/core';
//import * from jquery;

@Component({
  selector: 'app-panel-d3',
  templateUrl: './panel-d3.component.html',
  styleUrls: ['./panel-d3.component.scss']
})
export class PanelD3Component implements OnInit {

  constructor() { }

  ngOnInit() {

  }



  

}
