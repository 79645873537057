import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-panel-initial',
  templateUrl: './panel-initial.component.html',
  styleUrls: ['./panel-initial.component.scss']
})
export class PanelInitialComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
