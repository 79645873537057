import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-panel-caramello-caser',
  templateUrl: './panel-caramello-caser.component.html'
})
export class PanelCaramelloCaserComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
