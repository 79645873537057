import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-panel-interactive-cv',
  templateUrl: './panel-interactive-cv.component.html',
  styleUrls: ['./panel-interactive-cv.component.scss']
})
export class PanelInteractiveCvComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
